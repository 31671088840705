import * as React from "react";
import './Disclosures.css';
import logoSVG from "../../assets/logosvg.svg"
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import BTA1 from "../../assets/DisclosuresAssets/BTA1.png"
import BTA2 from "../../assets/DisclosuresAssets/BTA2.png"
import BTA3 from "../../assets/DisclosuresAssets/BTA3.png"
import CN1 from "../../assets/DisclosuresAssets/CN1.png"
import CN2 from "../../assets/DisclosuresAssets/CN2.png"

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const DISC_LINK_REDIRECT = "https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=82bc48fd-b01d-4711-945c-8bc39016f753&env=na4&acct=be89b9c4-f04a-4a69-a734-ea3ba53c37db&v=2";

function Disclosures(props) {

    const openDiscLink = () => {
        window.open(DISC_LINK_REDIRECT);
    }

    return (
        <div className="disclosures-wrap">
            <div className="disclanding-wrap">
                
                <div className="disclosures-landing">
                    <div className="disc-text">
                        {/* Adinqra's Disclosures */}
                        <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                        <p className="font-bold">Consumer Notice</p>
                    </div>
                    <hr className="extendbar"></hr>
                </div>

                <Carousel
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={7}
                >
                    <div>
                        <img src={CN1} alt="cn1" />
                        {/* <p className="legend">Page 1</p> */}
                    </div>
                    <div>
                        <img src={CN2} alt="cn2"/>
                    </div>
                </Carousel>

                {/* <button class="bg-gray-300 mx-auto w-min hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}


                {/* <button class="bg-red-300 mx-auto w-min hover:bg-red-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}
                
                <hr class="w-full mb-4 mt-4 h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" />

                <div className="disclosures-landing">
                    <div className="disc-text">
                        {/* Adinqra's Disclosures */}
                        <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                        <p className="font-bold">BTA</p>
                    </div>
                    <hr className="extendbar"></hr>
                </div>

                <Carousel
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={7}
                >
                    <div>
                        <img src={BTA1} alt='bta1' />
                        {/* <p className="legend">Page 1</p> */}
                    </div>
                    <div>
                        <img src={BTA2} alt="bta2" />
                    </div>
                    <div>
                        <img src={BTA3} alt="bta3" />
                    </div>
                </Carousel>

                {/* <button class="bg-red-300 mx-auto w-min hover:bg-red-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}


                {/* </div> */}

                <hr class="w-full mb-4 mt-4 h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" />


                {/* <div className="comingsoon">Coming soon</div> */}
                <div className="disc-section">

                    <button className="sign-btn" onClick={openDiscLink}>
                        Sign
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Disclosures;